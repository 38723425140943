import {onDomReady} from "../components/dynamic/observer";

class Blur {
    constructor(product) {
        this.product = product;
        this.initBlur();
    }

    initBlur() {
        this.product.addEventListener('click', (e) => {
            console.log();
            if (this.product.dataset.init) {
                return
            }
            e.preventDefault();
            this.product.classList.add('_no-blur');
            this.product.dataset.init = 'true';
        })
    }
}

onDomReady(() => {
    const products = document.querySelectorAll('[data-product-card]');
    if (products) {
        products.forEach((e) => {
            new Blur(e)
        })
    }
});