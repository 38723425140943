import IMask from "imask";
import axios from "axios";
import {onDomChanges} from "../../components/dynamic/observer";
import {singleInit} from "../../components/dynamic/bind";

onDomChanges(() => {
  document.querySelectorAll('[data-phone-field]').forEach((input) => {
    singleInit(input, 'initialized',  () => {
      const mask = IMask(input, {
        mask: '0 (000) 000-00-00'
      });

      mask.on("complete", () => {
        if (input.dataset.leadUrl) {
          axios.post(input.dataset.leadUrl, {
            phone: input.value
          });
        }
      });
    })
  })
});