import {onDomReady} from "../components/dynamic/observer";
import Jax from "../components/jax/jax";
import {debounce} from "lodash";

class Search {
    constructor() {
        this.searchForm = document.querySelector('[data-search-form]');
        this.searchContainer = document.querySelector('[data-search-container]');
        this.openButton = document.querySelector('[data-search-button]')
        this.bar = document.querySelector('[data-search-bar]')
        this.init();
        this.initButtons()
    }

    init() {
        this.searchForm.addEventListener('input', debounce(() => {
            const jax = new Jax(this.searchForm.getAttribute('action'));
            console.log(jax)
            jax.send(new FormData(this.searchForm)).then((html) => {
                this.searchContainer.innerHTML = html;

                if (!this.searchForm.querySelector("input").value) {
                    this.searchContainer.innerHTML = "";
                }

                let event = new Event('DOMContentMutated');
                document.dispatchEvent(event);
            });
        }, 200))
    }

    initButtons() {
        this.openButton.addEventListener('click', (e) => {
            this.bar.classList.toggle('_open');
        })

        // Отменяем отправку формы по Enter
        this.searchForm.addEventListener('keydown', function(event) {
            if(event.keyCode === 13) {
                event.preventDefault();
            }
        });
    }
}

onDomReady(() => {
    new Search();
});