import Office from "./Office";

export default class Offices {
  constructor(element) {
    this.rootElement = element;
    if (!element) {
      return;
    }
    this.settings = Object.assign({}, element.dataset);

    this.mapElement = element.querySelector('[data-offices-map]');
    this.map = undefined;
    this.placemark = undefined;

    this.activeTab = undefined;

    this.listElement = element.querySelector('[data-offices-list]');
    this.offices = [];
    this.activeOffice = undefined;

    this.initMapPending();
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  initMapPending() {
    if (window.ymaps) {
      this.initMap();
      return;
    }
    setTimeout(() => {
      this.initMapPending();
    }, 500);
  }

  initMap() {
    ymaps.ready(() => {
      this.initTabs();
      this.initObjects();
      this.createMap();
      // this.setActiveOfficeAfterInit();
      this.bindTabs();
    });
  }

  initTabs() {
    this.tabs = this.rootElement.querySelectorAll('[data-offices-tab]:not([data-initialized="true"])');
    if (this.tabs.length > 0) {
      this.activeTab = this.tabs[0];
      this.setTabInitialized(this.activeTab);
    }
  }

  initObjects() {
    this.rootElement.querySelectorAll('[data-list-item]')
      .forEach((listItemElement) => {
        const office = new Office(listItemElement);
        const placemark = this.initPlacemark([office.getLat(), office.getLng()]);

        office.setPlacemark(placemark);

        placemark.events.add('click', () => {
          this.onPlacemarkClick(office);
        });

        listItemElement.addEventListener('click', () => {
          this.onListElementClick(office);
        });

        this.offices.push(office);
      });
  }

  initPlacemark(coords) {
    this.placemark = new ymaps.Placemark(coords, {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: this.mapElement.dataset.mark,
      // Размеры метки.
      iconImageSize: [100, 100],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [0, 0],
    });
    return this.placemark;
  }

  createMap() {
    const rect = this.mapElement.getBoundingClientRect();
    let bottom = 220;
    if (rect.height) {
      bottom = (rect.height/2 - 30);
    }

    this.map = new ymaps.Map(this.mapElement, {
      center: [0, 0],
      zoom: 17,
      controls: ['zoomControl'],
    }, {
      zoomControlPosition: { right: 10, top: 'auto', left: 'auto', bottom: bottom + 'px' },
      zoomControlSize: 'small',
    });

    this.map.behaviors.disable('scrollZoom');

    this.map.margin.setDefaultMargin(this.getMapMargin());

    this.fitCenter();

    this.offices.map((office) => {
      if (office.settings.type === this.activeTab.dataset.officesTab) {
        this.map.geoObjects.add(office.getPlacemark());
      }
    })
  }

  setActiveOfficeAfterInit() {
    if (this.offices.length > 0) {
      this.activateOffice(this.offices[0]);
    }
  }

  getCenterCoordsByAllVisibleOffices() {
    // Берем все координаты от всех точек
    let minLat;
    let minLng;
    let maxLat;
    let maxLng;

    this.offices.forEach((office) => {
      if (office.settings.type === this.activeTab.dataset.officesTab) {
        if (office.settings.scaled === 'false') {
          if (minLat === undefined || minLat > office.getLat()) {
            minLat = office.getLat();
          }
          if (maxLat === undefined || maxLat < office.getLat()) {
            maxLat = office.getLat();
          }

          if (minLng === undefined || minLng > office.getLng()) {
            minLng = office.getLng();
          }
          if (maxLng === undefined || maxLng < office.getLng()) {
            maxLng = office.getLng();
          }
        }
      }
    });

    return [[minLat, minLng], [maxLat, maxLng]];
  }

  fitCenter() {
    const coords = this.getCenterCoordsByAllVisibleOffices();
    let minLat = coords[0][0];
    let minLng = coords[0][1];
    let maxLat = coords[1][0];
    let maxLng = coords[1][1];
    if (minLat === maxLat && minLng === maxLng) {
      // Если офис один, то центрируем карту и берем зум от этого офиса
      this.moveMapCenterTo(
          [this.offices[0].getLat(), this.offices[0].getLng()],
          parseInt(this.offices[0].settings.zoom, 10)
      );
    } else {
      // Определяем центр и оптимальный зум
      ymaps.util.requireCenterAndZoom(
          this.map.getType(),
          coords,
          this.map.container.getSize(),
          { margin: this.getMapMargin() },
      ).then((result) => {
        this.map.setCenter(result.center, result.zoom, {duration: 500});
      });
    }
  }

  getMapMargin() {
    if (this.isMobile()) {
      return [31, 31, 31, 31];
    }
    const bounds = this.listElement.getBoundingClientRect();
    return [62, 62, 62, bounds.right + 62];
  }

  moveMapCenterTo(coords, officeZoom) {
    if (this.isMobile()) {
      if (!isNaN(officeZoom)) {
        this.map.setCenter(coords, officeZoom);
      } else {
        this.map.setCenter(coords, 16);
      }
    } else {
      if (!isNaN(officeZoom) && this.map.getZoom() !== officeZoom) {
        this.map.setZoom(officeZoom, {duration: 600})
            .then(() => {
              this.map.panTo(
                  coords, {
                    duration: 600,
                    flying: true,
                    useMapMargin: true,
                  },
              );
            });
      } else {
        this.map.panTo(
            coords, {
              duration: 600,
              flying: true,
              useMapMargin: true,
            },
        );
      }
    }
  }

  refreshMap() {
    if (this.isMobile()) {
      this.map.container.fitToViewport();
    }
  }

  onListElementClick(office) {
    this.activateOffice(office);
  }

  deactivateOffice() {
    if (this.activeOffice) {
      this.activeOffice.getPlacemark().options.set({
        iconImageSize: [34, 40],
        iconImageOffset: [-17, -40],
      });
      this.activeOffice.hide();
      this.activeOffice = null;
    }
  }

  activateOffice(office) {
    this.deactivateOffice();
    office.show();
    office.getPlacemark().options.set({
      iconImageSize: [100, 100],
      iconImageOffset: [-20, -46],
    });
    this.refreshMap();
    this.moveMapCenterTo([office.getLat(), office.getLng()], parseInt(office.settings.zoom, 10));
    this.activeOffice = office;
  }

  removePlacemarks() {
    this.offices.forEach((office) => {
      this.map.geoObjects.remove(office.getPlacemark());
    });
  }

  setActivePlacemarksByActiveTab() {
    this.offices.map((office) => {
      if (office.settings.type === this.activeTab.dataset.officesTab) {
        this.map.geoObjects.add(office.getPlacemark());
      }
    });
  }

  bindTabs() {
    this.tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        this.activeTab = tab;
        this.removePlacemarks();

        if (!this.activeTab.getAttribute('data-initialized')) {
          this.offices.map((office) => {
            if (office.settings.type === this.activeTab.dataset.officesTab) {
              office.defineHeight();
            }
          });

          this.setTabInitialized(this.activeTab);
        }

        this.fitCenter();

        setTimeout(() => {
          this.setActivePlacemarksByActiveTab();
        }, 600);
      });
    })
  }

  onPlacemarkClick(office) {
    this.activateOffice(office);
  }

  setTabInitialized(tab) {
    tab.setAttribute('data-initialized', 'true');
  }
}