<template>
    <div class="range-field" :class="blockClasses">
        <label v-if="field.label" :class="elementClasses('label')">{{ field.label }}</label>
        <div class="range-field__wrapper"
             :class="elementClasses('wrapper')"
             v-if="valueItem.value"
        >
            <div :class="elementClasses('values')">
                <input type="text" v-model="from" @focus="focusFrom" @blur="blurFrom" :class="elementClasses('input')">
                <input type="text" v-model="to" @focus="focusTo" @blur="blurTo" :class="elementClasses('input')">
            </div>

            <!--
                :dot-size="8"
            -->
            <slider
                    :model-value="valueModel"
                    :min="field.data.min"
                    :max="field.data.max"
                    :step="field.data.step"
                    :format="sliderFormat"
                    @input="onSliderInput"
                    class="range-field__slider"
            />
        </div>
    </div>
</template>

<script>
import { fieldMixin } from "./fieldMixin";
import Slider from '@vueform/slider'

export default {
  name: "RangeField",
  mixins: [
    fieldMixin
  ],
  data() {
    return {
      fromFocused: false,
      toFocused: false,
    }
  },
  computed: {
    valueModel() {
      if (!this.valueItem.value) {
        return [0, 0];
      }
      return [
        this.valueItem.value.from,
        this.valueItem.value.to
      ]
    },
    sliderFormat() {
      return {
        prefix: this.field.data.prefix,
        suffix: this.field.data.postfix,
        decimals: 0,
        thousand: this.isRankSplitThousands ? ' ' : ''
      };
    },
    isRankSplitThousands() {
      return this.field.data.rankSplit === 'thousands';
    },
    from: {
      get() {
        return this.formatValue(this.valueItem.value.from, this.fromFocused);
      },
      set(value) {
        this.valueItem.value.from = this.cleanValue(value);
      }
    },
    to: {
      get() {
        return this.formatValue(this.valueItem.value.to, this.toFocused);
      },
      set(value) {
        this.valueItem.value.to = this.cleanValue(value);
      }
    }
  },
  components: {
    Slider
  },
  methods: {
    checkValue() {
      if (!this.valueItem.value) {
        this.valueItem.value = {
          from: this.field.data.min,
          to: this.field.data.max
        };
      }
    },
    onSliderInput(value) {
      if (value[0] && value[1]) {
        this.valueItem.value.from = value[0];
        this.valueItem.value.to = value[1];
      }
    },
    focusFrom() {
      this.fromFocused = true;
    },
    focusTo() {
      this.toFocused = true;
    },
    blurFrom() {
      this.fromFocused = false;
    },
    blurTo() {
      this.toFocused = false;
    },
    cleanValue(value) {
      if (typeof value !== 'string' && !(value instanceof String)) {
        value = `${value}`;
      }
      return value.replace(/[^0-9]/g, '')
    },
    formatValue(value, hidePostfix) {
      hidePostfix = hidePostfix | false;
      let formattedValue = this.cleanValue(value);
      if (this.isRankSplitThousands) {
        formattedValue = this.numberWithSpaces(formattedValue);
      }
      if (this.field.data.prefix) {
        formattedValue = this.field.data.prefix + formattedValue;
      }
      if (this.field.data.postfix && !hidePostfix) {
        formattedValue = formattedValue + this.field.data.postfix;
      }
      return formattedValue;
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  },
  mounted() {
    this.checkValue();
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">

</style>