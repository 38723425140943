import {onDomReady} from "../components/dynamic/observer";
import Datepicker from 'vanillajs-datepicker/Datepicker';
import 'vanillajs-datepicker/css/datepicker.css';
import ru from 'vanillajs-datepicker/locales/ru';
Object.assign(Datepicker.locales, ru);

class Proof {
    constructor(proofContainer) {
        this.container = proofContainer;
        this.confirmButton = proofContainer.querySelector('[data-proof-confirm]');
        this.exitButton = proofContainer.querySelector('[data-proof-exit]');
        this.dateElem = document.querySelector('input[name="date"]');

        this.datePicker = new Datepicker(this.dateElem, {
            language: 'ru',
            format: 'dd/mm/yyyy'
        });

        this.init();
    }

    init() {
        this.onConfirmClick();
        this.onExitClick();
    }

    onConfirmClick() {
        this.confirmButton.addEventListener('click', () => {
            if (!this.dateElem.value) {
                alert('Укажите дату рождения')
            } else {
                const date1 = this.datePicker.getDate();
                const date2 = new Date(Date.now());
                const diffTime = Math.abs(date2 - date1);
                const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

                if (diffYears >= 18) {
                    sessionStorage.setItem('isAgeCheked', 'isAgeCheked');
                    this.removeLimit();
                } else {
                    this.exitButton.click()
                }
            }
        })
    }

    onExitClick() {
        this.exitButton.addEventListener('click', () => {
            window.close();
        })
    }

    removeLimit() {
        this.container.classList.remove('_before-proof');
        document.body.classList.remove('_proof')
    }
}

onDomReady(() => {
    const proofContainer = document.querySelector('[data-proof-container]');
    const isChecked = sessionStorage.getItem( 'isAgeCheked');
    if (!isChecked) {
        proofContainer.classList.add('_before-proof');
        document.body.classList.add('_proof')
        if (proofContainer) {
            new Proof(proofContainer);
        }
    }
});